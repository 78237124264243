import {
  InputCheckbox,
  SurfaceFieldset,
  SimplifiedInputAddress,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import {
  ADDRESS_TYPE_PHYSICAL_HOME,
  ADDRESS_TYPE_MAILING,
} from "@root/constants";

/**
 * Address input compoment with separate inputs for physical and mailing address
 * Input names are set to play nice with specific active admin screens
 * Offloads the actual heavy lifting to SingleAddressInput.
 */
const Address = ({ address: initialAddress, title }) => {
  const [physicalAddress, setPhysicalAddress] = useState(initialAddress);
  const [mailingAddress, setMailingAddress] = useState({});
  const [mailingSameAsPhysical, setMailingSameAsPhysical] = useState(true);

  return (
    <div>
      <SurfaceFieldset title={title || <T t="address.primary_residence" />}>
        <SimplifiedInputAddress
          allowedCountryCodes={["US"]}
          usePrimarySubdivisionCodeAsValue
          addressType={ADDRESS_TYPE_PHYSICAL_HOME}
          useAutocomplete
          onChange={setPhysicalAddress}
          value={physicalAddress}
          fieldNameOverrides={{
            addressLine1:
              "application[applicant_agency_profile_attributes][address_street]",
            addressLine2:
              "application[applicant_agency_profile_attributes][address_street_line2]",
            city: "application[applicant_agency_profile_attributes][address_city]",
            primarySubdivision:
              "application[applicant_agency_profile_attributes][address_state]",
            postalCode:
              "application[applicant_agency_profile_attributes][address_zipcode]",
            countryCode:
              "application[applicant_agency_profile_attributes][address_country_code]",
          }}
        />
        <InputCheckbox
          label={<T t="address.mailing_address_same_as_physical" />}
          value={mailingSameAsPhysical}
          name={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          id={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          onChange={checked => {
            if (!checked) {
              setMailingAddress({});
            }
            setMailingSameAsPhysical(checked);
          }}
        />
      </SurfaceFieldset>
      {!mailingSameAsPhysical && (
        <SurfaceFieldset
          title={title || <T t="address.what_is_your_mailing_address" />}
        >
          <SimplifiedInputAddress
            value={mailingAddress}
            usePrimarySubdivisionCodeAsValue
            useAutocomplete
            onChange={setMailingAddress}
            addressType={ADDRESS_TYPE_MAILING}
            allowedCountryCodes={["US"]}
            fieldNameOverrides={{
              addressLine1:
                "application[applicant_agency_profile_attributes][mailing_address_street]",
              addressLine2:
                "application[applicant_agency_profile_attributes][mailing_address_street_line2]",
              city: "application[applicant_agency_profile_attributes][mailing_address_city]",
              primarySubdivision:
                "application[applicant_agency_profile_attributes][mailing_address_state]",
              postalCode:
                "application[applicant_agency_profile_attributes][mailing_address_zipcode]",
              countryCode:
                "application[applicant_agency_profile_attributes][mailing_address_country_code]",
            }}
          />
        </SurfaceFieldset>
      )}
    </div>
  );
};

Address.propTypes = {
  /** Address of the applicant */
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    city: PropTypes.string,
    primarySubdivisionCode: PropTypes.string,
    primarySubdivisionName: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  title: PropTypes.string,
  /** US States options for user to choose */
  usStateOptions: PropTypes.array,
};

export default Address;
