/** Conversion utilities for translating back and forth between
 * the way we're storing data in our DB and the way we display
 * inputs and information in the UI
 *
 * Our UI takes a more step by step approach to asking users for
 * information, whereas our DB only stores the information it
 * needs to extract this information for our UI.
 */
import { isEmpty, isNil } from "lodash";

import { translationWithRoot } from "@components/T";

import {
  mapConstantSetToValues,
  mapConstantToValue,
} from "@lib/constantsConversion";

import {
  RELATIVE,
  PARTNER,
  KINSHIP_RELATIONSHIPS,
  PARTNER_STATUSES,
  PLACEMENT,
  NON_PLACEMENT,
  OTHER,
} from "@root/constants";

const { t } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});

/** Returns the agency human of the relationship that is NOT the keystone agency human */
export const determineNonKeystoneId = ({
  keystoneAgencyHumanId,
  relationship: {
    sourceAgencyHuman: { id: sourceAgencyHumanId },
    destinationAgencyHuman: { id: destinationAgencyHumanId },
  },
}) =>
  sourceAgencyHumanId.toString() === keystoneAgencyHumanId.toString()
    ? destinationAgencyHumanId
    : sourceAgencyHumanId;

/** Translates relationship data as stored in the database into the format expected
 * by our UI. Our UI does more hand holding than our database model
 */
export const parseRelationshipForUI = ({
  emotionalRelationshipStatuses = [],
  kinshipRelationship,
  parentalLine,
  lineageType,
  levelsOfSupport,
  ...relationshipDetails
}) => {
  /** In our UI, we split out the values for kinship relationship into three
   * different dropdowns, necessitating this shim to distinguish between values
   * for each
   */
  const partnerStatusForUI = PARTNER_STATUSES.includes(kinshipRelationship)
    ? mapConstantToValue({
        translationKey: "relationships.partner_status",
        value: kinshipRelationship,
      })
    : undefined;

  let kinshipRelationshipForUI;
  if (KINSHIP_RELATIONSHIPS.includes(kinshipRelationship)) {
    kinshipRelationshipForUI = mapConstantToValue({
      translationKey: "relationships.kinship_relationships",
      value: kinshipRelationship,
    });
  } else if (!isNil(partnerStatusForUI)) {
    kinshipRelationshipForUI = mapConstantToValue({
      translationKey: "relationships.kinship_relationships",
      value: PARTNER,
    });
  }

  const isRelative = [...KINSHIP_RELATIONSHIPS, ...PARTNER_STATUSES].includes(
    kinshipRelationship
  );
  const parsedKinshipRelationship = {
    relationshipCategory: isRelative
      ? { label: t("category.relative"), value: RELATIVE }
      : mapConstantToValue({
          translationKey: "relationships.category",
          value: kinshipRelationship,
        }),
    kinshipRelationship: kinshipRelationshipForUI,
    partnerStatus: partnerStatusForUI,
  };

  return {
    ...relationshipDetails,
    ...parsedKinshipRelationship,
    parentalLine: isNil(parentalLine)
      ? undefined
      : mapConstantSetToValues({
          constant: parentalLine.split("_and_"),
          translationKey: "relationships.parental_lines",
        }),
    emotionalRelationshipStatuses: mapConstantSetToValues({
      constant: emotionalRelationshipStatuses,
      translationKey: "relationships.emotional_status",
    }),
    lineageType: mapConstantToValue({
      translationKey: "relationships.lineage_type",
      value: lineageType,
    }),
    levelsOfSupport: Object.keys(levelsOfSupport || {}),
    levelsOfSupportOtherDescription: levelsOfSupport?.other?.details,
  };
};

/** Wrapper around parseRelationshipForUI for multiple relationships */
export const relationshipsForUI = ({
  data: { agencyHumanRelationships = [] } = {},
  keystoneAgencyHumanId,
}) =>
  agencyHumanRelationships.reduce((parsedRelationships, relationship) => {
    /** The keystone agency human is the person whose relationships to each
     * child in the sibling group we're storing details for. As a result, we
     * want to key by the children's agency human ids as those are the unique
     * agency human ids across the relationships we're collectively editing
     *
     * It's possible for both ids to belong to children, in the case of siblings,
     * which is why we are using the terms keystone and non-keystone
     */
    const nonKeystoneAgencyHumanId = determineNonKeystoneId({
      keystoneAgencyHumanId,
      relationship,
    });

    return {
      ...parsedRelationships,
      [nonKeystoneAgencyHumanId]: {
        nonKeystoneAgencyHumanId,
        keystoneAgencyHumanId,
        ...parseRelationshipForUI(relationship),
      },
    };
  }, {});

/** Translates the data from the UI into the format expected by our database model.
 * Our UI does more hand holding than our database model, so this consolidates things
 * down to the data we actually need to store
 */
export const relationshipForDB = ({
  id,
  emotionalRelationshipStatuses = [],
  relationshipCategory = {},
  kinshipRelationship = {},
  partnerStatus = {},
  parentalLine = [],
  lineageType = {},
  levelsOfSupport = [],
  levelsOfSupportOtherDescription,
  /** We want to use the keystone/non-keystone agency human ids rather than source/destination
   * agency humans as the logic to determine which agency human is the source and which is
   * the destination is handled by our services layer.
   *
   * We're not updating any details about the agency humans, we're only updating relationship
   * details, so we don't need to worry about losing details on the individuals here
   */
  sourceAgencyHuman: _sourceAgencyHuman,
  destinationAgencyHuman: _destinationAgencyHuman,
  ...relationshipDetails
}) => {
  /** relationshipCategory, kinshipRelationship, and partnerStatus all roll up
   * into kinshipRelationship in our db entries
   */
  let kinshipRelationshipForDB = kinshipRelationship.value;
  if (isNil(kinshipRelationshipForDB)) {
    kinshipRelationshipForDB = relationshipCategory.value;
  } else if (kinshipRelationship.value === PARTNER) {
    kinshipRelationshipForDB = partnerStatus.value;
  }

  const parentalLineForDB = parentalLine
    .map(({ value }) => value)
    .sort()
    .join("_and_");

  return {
    ...relationshipDetails,
    relationshipId: id,
    kinshipRelationship: kinshipRelationshipForDB,
    parentalLine: isEmpty(parentalLineForDB) ? null : parentalLineForDB,
    emotionalRelationshipStatuses: emotionalRelationshipStatuses.map(
      ({ value }) => value
    ),
    lineageType: lineageType.value || null,
    levelsOfSupport: levelsOfSupport.reduce((los, key) => {
      const topLevelKeys = {};
      if (key.match(`^${PLACEMENT}`)) topLevelKeys.placement = null;
      if (key.match(`^${NON_PLACEMENT}`)) topLevelKeys.non_placement = null;
      const keyValue =
        key === OTHER
          ? { [key]: { details: levelsOfSupportOtherDescription } }
          : { [key]: null };

      return {
        ...los,
        ...keyValue,
        ...topLevelKeys,
      };
    }, {}),
  };
};
