import { Button, Flex, GraphQLDataTable } from "@heart/components";
import PropTypes from "prop-types";
import {
  newPreventionAgencyServiceReferralPath,
  personPath,
  preventionAgencyServiceReferralPath,
  serviceReferralsPersonPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import AgencyAutocompleteQuery from "@graphql/queries/AgencyAutocomplete.graphql";
import FetchReferralRecipients from "@graphql/queries/prevention/FetchReferralRecipients.graphql";

const { t } = translationWithRoot(
  "prevention.agency_service_referrals.dashboard"
);

/**
 * Renders a table for the dashboard
 * @param status - The status of the referral in question
 * @param currentUserAgencyId
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardTable = ({ status, currentUserAgencyId }) => {
  const columns = [
    {
      id: "person_name",
      columnName: { name: t("columns.person_name") },
      cell: data => {
        const human = data.agencyHuman;
        if (human.childId) {
          return (
            <a href={serviceReferralsPersonPath(human.id)}>{human.fullName}</a>
          );
        }
        return <a href={personPath(human.id)}>{human.fullName}</a>;
      },
    },
    {
      id: "referral_id",
      columnName: { name: t("columns.referral_id") },
      cell: data => data.agencyServiceReferral.id,
    },
    {
      id: "start_date",
      columnName: { name: t("columns.service_start_date") },
      cell: data => new Date(data.startDate).toLocaleDateString(),
    },
    {
      id: "end_date",
      columnName: { name: t("columns.service_end_date") },
      cell: data => new Date(data.endDate).toLocaleDateString(),
    },
    {
      id: "service_name",
      columnName: { name: t("columns.service_name") },
      cell: data => data.agencyServiceReferral.agencyService.name,
    },
    {
      id: "agency",
      columnName: { name: t("columns.agency") },
      cell: data => {
        const { referringAgency, providerAgency } =
          data.agencyServiceReferral.agencyService;

        // The default is to explicitly state the referring and provider agencies
        // At the moment, this can only happen for Binti Admins.
        let sentence = [
          referringAgency.name,
          t("columns.agency_sent_to").toLowerCase(),
          providerAgency.name,
        ];

        if (referringAgency.id === providerAgency.id) {
          sentence = [t("columns.agency_self_assigned")];
        } else if (referringAgency.id === currentUserAgencyId) {
          sentence = [t("columns.agency_sent_to"), providerAgency.name];
        } else if (providerAgency.id === currentUserAgencyId) {
          sentence = [t("columns.agency_received_from"), referringAgency.name];
        }

        return sentence.join(" ");
      },
    },
    {
      id: "actions",
      columnName: { name: t("columns.actions"), justify: "end" },
      cell: data => (
        <a
          href={preventionAgencyServiceReferralPath(
            data.agencyServiceReferral.id
          )}
        >
          {t("actions.view")}
        </a>
      ),
    },
  ];

  const filters = [
    {
      label: t("filters.recipient_name_contains"),
      type: "search",
      field: "recipientNameContains",
    },
    {
      label: t("filters.service_name"),
      type: "search",
      field: "serviceNameContains",
    },
    {
      label: t("filters.provider_agency"),
      type: "autocomplete_select",
      field: "providerAgency",
      query: AgencyAutocompleteQuery,
      appliedFilterValueFor: value => value.map(agency => agency.id),
      valuesFromResponse: data =>
        data.agencyMatches.map(agency => ({
          label: agency.name,
          value: agency.id,
        })),
    },
    {
      label: t("filters.referring_agency"),
      type: "autocomplete_select",
      field: "referringAgency",
      query: AgencyAutocompleteQuery,
      appliedFilterValueFor: value => value.map(agency => agency.id),
      valuesFromResponse: data =>
        data.agencyMatches.map(agency => ({
          label: agency.name,
          value: agency.id,
        })),
    },
    {
      label: "",
      type: "custom_dates",
      field: "startDate",
      customDatesLabels: {
        start: t("filters.start_date_after"),
        end: t("filters.start_date_before"),
      },
    },
    {
      label: "",
      type: "custom_dates",
      field: "endDate",
      customDatesLabels: {
        start: t("filters.end_date_after"),
        end: t("filters.end_date_before"),
      },
    },
  ];

  return (
    <GraphQLDataTable
      columns={columns}
      query={FetchReferralRecipients}
      queryTitle={"preventionReferralRecipients"}
      defaultFilters={{ referralStatus: status.toUpperCase() }}
      filtersToPreserveOnClear={["status"]}
      filters={filters}
      actions={
        <Flex justify="end" column>
          <Button
            href={newPreventionAgencyServiceReferralPath()}
            variant="secondary"
          >
            {t("actions.create_new")}
          </Button>
        </Flex>
      }
    />
  );
};

DashboardTable.propTypes = {
  status: PropTypes.string.isRequired,
  currentUserAgencyId: PropTypes.string,
};

export default DashboardTable;
