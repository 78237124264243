import { Breadcrumbs, Layout, SurfaceForm, Actions } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  adminChildrenPath,
  agencyServicePath,
  agencyServicesPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import AddAgencyServiceRate from "@components/prevention/agency_service_rates/AddAgencyServiceRate";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

const { T, t } = translationWithRoot("prevention.agency_service_rates");

/**
 * Page to add a new service rate to an existing agency service
 * @param {@BintiPropTypes.ID} agencyServiceId - ID of the agency service
 * @param {Array} billingStructures - List of billing structures
 */
const AddAgencyServiceRatePage = ({ agencyServiceId, billingStructures }) => {
  const [error, setError] = useState(null);

  const checkForErrors = sections => {
    if (sections.length === 0) {
      setError(t("errors.add_service_rate"));
    } else {
      setError(null);
    }
  };

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { agencyServiceRates: [] },
  });

  const multiUpdate = value => {
    if (!_.isEqual(value, formState.agencyServiceRates)) {
      setFormAttribute("agencyServiceRates")(value);
    }
  };

  const handleSubmit = () => {
    if (formState.agencyServiceRates.length === 0) {
      setError(t("errors.add_service_rate"));
    }
  };

  return (
    <Layout
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="breadcrumbs.children_and_youth"></T>,
              href: adminChildrenPath(),
            },
            {
              label: <T t="breadcrumbs.agency_services_dashboard"></T>,
              href: agencyServicesPath(),
            },
            {
              label: <T t="breadcrumbs.agency_service_details"></T>,
              href: agencyServicePath(agencyServiceId),
            },
            {
              label: <T t="add"></T>,
              href: "#",
            },
          ]}
        />
      }
      main={{
        content: (
          <SurfaceForm
            title={<T t="create_new" />}
            actions={<Actions />}
            onSubmit={preventDefault(handleSubmit)}
          >
            <AddAgencyServiceRate
              multiUpdate={multiUpdate}
              checkForErrors={checkForErrors}
              billingStructures={billingStructures}
              error={error}
            />
          </SurfaceForm>
        ),
      }}
    />
  );
};

AddAgencyServiceRatePage.propTypes = {
  agencyServiceId: BintiPropTypes.ID.isRequired,
  agencyServiceName: PropTypes.string.isRequired,
  billingStructures: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default AddAgencyServiceRatePage;
