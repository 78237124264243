import {
  Actions,
  InputFilterable,
  InputRadioGroup,
  InputText,
  SurfaceForm,
} from "@heart/components";
import { ReactNodeLike } from "prop-types";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";

import preventDefault from "@lib/preventDefault";

import { AgencyServiceFormState, FormProps } from "./types";

const { T, t } = translationWithRoot("prevention.agency_services");

/**
 * Shared form component between creating and editing an agency service
 */
const AgencyServiceForm = ({
  referringAgencies,
  providerAgencies,
  onSubmit,
  setFormAttribute,
  ebps,
  areasOfCoverage,
  fundingSources,
  serviceTypes,
  children,
  formState,
  cancelHref,
}: {
  onSubmit: () => void;
  setFormAttribute: (key: string) => (val: unknown) => void;
  children?: ReactNodeLike;
  formState: AgencyServiceFormState;
  cancelHref: string;
} & Omit<FormProps, "billingStructures">) => (
  <SurfaceForm
    actions={<Actions cancelHref={cancelHref} />}
    onSubmit={preventDefault(onSubmit)}
    title={""}
  >
    <If condition={referringAgencies}>
      <InputFilterable
        label={<T t="referring_agency" />}
        onChange={setFormAttribute("referringAgency")}
        required
        values={referringAgencies}
        value={formState.referringAgency}
      />
    </If>
    <InputFilterable
      label={<T t="provider" />}
      onChange={setFormAttribute("providerAgency")}
      required
      values={providerAgencies}
      value={formState.providerAgency}
    />
    <InputText
      // @ts-expect-error - legacy label prop definition
      label={<T t="service_name" />}
      onChange={setFormAttribute("name")}
      required
      value={formState.name}
    />
    <InputRadioGroup
      label={<T t="ffpsa_program" />}
      name="ffpsa-program"
      onChange={setFormAttribute("isFfpsa")}
      orientation="row"
      required
      values={[
        { value: true, label: t("ffpsa_yes") },
        { value: false, label: t("ffpsa_no") },
      ]}
      value={formState.isFfpsa}
    />
    <InputFilterable
      label={<T t="corresponding_ebp_if_applicable" />}
      required
      onChange={setFormAttribute("evidenceBasedPractice")}
      values={ebps}
      value={formState.evidenceBasedPractice}
    />
    <InputFilterable
      label={<T t="funding_sources" />}
      onChange={setFormAttribute("fundingSources")}
      name="funding-sources"
      required
      isClearable
      isMulti
      allowCreate
      values={fundingSources}
      value={formState.fundingSources}
    />
    <InputFilterable
      label={<T t="service_types" />}
      onChange={setFormAttribute("serviceTypes")}
      name="service-types"
      isClearable
      isMulti
      allowCreate
      values={serviceTypes}
      value={formState.serviceTypes}
    />
    <InputFilterable
      label={<T t="areas_of_coverage" />}
      onChange={setFormAttribute("areasOfCoverage")}
      name="areas-of-coverage"
      isClearable
      isMulti
      values={areasOfCoverage}
      value={formState.areasOfCoverage}
    />
    {children}
  </SurfaceForm>
);

export default AgencyServiceForm;
