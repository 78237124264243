import { Flex, InputCheckbox, InputRadioGroup, Link } from "@heart/components";
import classNames from "classnames";
import {
  difference,
  flatten,
  intersection,
  isEmpty,
  isNil,
  without,
} from "lodash";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo, useState } from "react";

import { translationWithRoot } from "@components/T";

import {
  inputCommonPropTypes,
  InputGroupLayout,
  inputGroupCommonPropTypes,
} from "./common";
import styles from "./common/RadioAndCheckboxCommonStyles.module.scss";

const { t } = translationWithRoot(
  "heart.components.inputs.input_checkbox_group"
);

const optionValue = val => val.value || val;
const optionLabel = val => val.label || val;

/** Remove any radio button values */
const rawCheckboxValues = ({ value, allRadioValues }) =>
  difference(value || [], flatten(Object.values(allRadioValues)));
/** Determine values for selected checkboxes */
const rawCheckboxValueArray = ({ value, allRadioValues }) =>
  Array.from(rawCheckboxValues({ value, allRadioValues }).map(optionValue));
/** Determine values for selected radio groups */
const rawRadioValues = ({ value, allRadioValues }) => {
  const selectedValues = {};
  Object.keys(allRadioValues).forEach(checkbox => {
    const [radioValueForCheckbox] = intersection(
      allRadioValues[checkbox],
      value
    );
    selectedValues[checkbox] = radioValueForCheckbox;
  });
  return selectedValues;
};

/** extract the values for all the checkboxes and the values for all the
 * radio button subgroups, associated with their top level checkboxes.
 *
 * we determine the values separately, as any Select All functionality should
 * only apply to checkboxes, and so that we can correctly determine which values
 * are associated with radio groups when determining what values are selected
 * in controlled inputs
 */
const determineAllValues = values => {
  let allCheckboxValues = [];
  let allRadioValues = {};
  values.forEach(val => {
    const optionVal = optionValue(val);
    /** We only want to add the value as a checkbox if there aren't any nested options, or
     * if those nested options are a radio group.
     *
     * If the nested options are checkboxes, the top level label isn't a selectable option
     */
    if (isEmpty(val.options) || val.nestedAsRadio) {
      allCheckboxValues.push(optionVal);
    }
    /** If the options are a radio group, add those values to allRadioValues */
    if (val.options && val.nestedAsRadio) {
      val.options.forEach(radioVal => {
        allRadioValues[optionVal] = [
          ...(allRadioValues[optionVal] || []),
          optionValue(radioVal),
        ];
      });
    }
    /** If the options are nested checkboxes, recursively determine what the value of
     * those sub-options are
     */
    if (val.options && !val.nestedAsRadio) {
      const {
        allCheckboxValues: subCheckboxVals,
        allRadioValues: subRadioVals,
      } = determineAllValues(val.options);

      allCheckboxValues = allCheckboxValues.concat(subCheckboxVals);
      allRadioValues = {
        ...allRadioValues,
        ...subRadioVals,
      };
    }
  });

  return { allCheckboxValues, allRadioValues };
};

/**
 * A collection of checkbox inputs handled in aggregate.  When included in a `<form>` POST,
 * these are represented as a list of values in the HTTP params.
 *
 * **Note**: Using the `required` prop will *not* prevent form submissions.
 *
 * Relevant Cypress commands:
 *   * `cy.findRadioOrCheckboxInput(groupName, label).check()`
 */
const InputCheckboxGroup = props => {
  const { onChange, onBlur, value, values, selectAll = {} } = props;
  const selectAllClassName = classNames({
    [styles.indentAllInputs]: selectAll.type === "checkbox",
  });
  const selectAllLabel = selectAll.label || t("select_all");
  const { allCheckboxValues, allRadioValues } = useMemo(
    () => determineAllValues(values),
    [values]
  );

  const [allSelected, setAllSelected] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState(
    rawCheckboxValueArray({ value, allRadioValues })
  );
  const [nestedRadioValues, setNestedRadioValues] = useState(
    rawRadioValues({ value, allRadioValues })
  );

  const allValuesChecked = checkboxValues.length === allCheckboxValues.length;
  useEffect(() => {
    if (allValuesChecked) setAllSelected(true);
    else setAllSelected(false);
  }, [allValuesChecked]);
  useEffect(
    () => setCheckboxValues(rawCheckboxValueArray({ value, allRadioValues })),
    [value, allRadioValues]
  );
  const handleChange = e => {
    let newValues;
    let newRadioValues = {};
    if (e.target.checked) {
      /** if the checkbox is being checked, add it to the list of values */
      newValues = [...checkboxValues, e.target.value];
    } else {
      /** if it's unchecked, reset any potential associated radio group value
       * and remove the checkbox's value from the list of values
       */
      newRadioValues = {
        ...nestedRadioValues,
        [optionValue(e.target)]: false,
      };
      setNestedRadioValues(newRadioValues);
      newValues = without(checkboxValues, e.target.value);
    }

    setCheckboxValues(newValues);
    if (onChange)
      onChange([
        ...newValues,
        /** If nested radio groups are present, we add the value of those radio
         * groups to the returned set of selected values
         */
        ...Object.values(newRadioValues).filter(Boolean),
      ]);
  };

  const generateOption = ({ val, disabled, name, nested }) => (
    <li key={optionValue(val)}>
      <label
        className={classNames(styles.radioCheckContainer, selectAllClassName, {
          [styles.indent]: nested,
        })}
      >
        <input
          type="checkbox"
          onChange={handleChange}
          onBlur={onBlur}
          checked={checkboxValues.includes(optionValue(val))}
          name={name ? `${name}[]` : undefined}
          value={optionValue(val)}
          disabled={disabled || val.disabled}
        />
        {optionLabel(val)}
      </label>
    </li>
  );

  const SelectAllControl = () => {
    if (selectAll.type === "checkbox") {
      return (
        <InputCheckbox
          label={selectAllLabel}
          value={allSelected}
          onChange={checked => {
            setCheckboxValues(checked ? allCheckboxValues : []);
            setAllSelected(checked);
            if (!checked) setNestedRadioValues({});
            if (onChange)
              onChange(
                checked
                  ? [
                      ...allCheckboxValues,
                      ...Object.values(nestedRadioValues).filter(Boolean),
                    ]
                  : []
              );
          }}
        />
      );
    }
    if (selectAll.type === "links") {
      return (
        <Flex row>
          <Link
            onClick={() => {
              setCheckboxValues(allCheckboxValues);
              if (onChange)
                onChange([
                  ...allCheckboxValues,
                  ...Object.values(nestedRadioValues).filter(Boolean),
                ]);
            }}
            disabled={allValuesChecked}
          >
            {selectAllLabel}
          </Link>
          <Link
            onClick={() => {
              setCheckboxValues([]);
              setNestedRadioValues([]);
              if (onChange) onChange([]);
            }}
            disabled={isEmpty(checkboxValues)}
          >
            {t("clear")}
          </Link>
        </Flex>
      );
    }
    return null;
  };

  /** Nested radio group options, only displayed when the top level
   * checkbox is checked */
  const NestedRadioGroup = ({ val }) => {
    const { options, value: nestedValue } = val;
    return (
      <If condition={!isNil(options) && checkboxValues.includes(nestedValue)}>
        <div className={classNames(selectAllClassName, styles.indent)}>
          <InputRadioGroup
            hideLabel
            label={t("options_for_group", { group: optionLabel(val) })}
            values={options}
            value={nestedRadioValues[optionValue(val)] || false}
            onChange={selectedOption => {
              const newRadioValues = {
                ...nestedRadioValues,
                [optionValue(val)]: selectedOption,
              };
              setNestedRadioValues(newRadioValues);
              if (onChange)
                onChange([
                  ...checkboxValues,
                  ...Object.values(newRadioValues).filter(Boolean),
                ]);
            }}
          />
        </div>
      </If>
    );
  };
  NestedRadioGroup.propTypes = {
    val: PropTypes.shape({
      options: PropTypes.array,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.string,
              label: PropTypes.string,
            })
          ),
        ]),
      ]),
    }),
    disabled: PropTypes.bool,
  };

  /** Nested checkbox options */
  const NestedCheckboxGroup = ({ val, disabled }) => {
    const { options } = val;
    return (
      <If condition={!isNil(options)}>
        {options.map(nestedVal =>
          generateOption({
            val: nestedVal,
            disabled,
            name,
            nested: true,
          })
        )}
      </If>
    );
  };
  NestedCheckboxGroup.propTypes = {
    val: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        options: PropTypes.array,
      }),
    ]),
    disabled: PropTypes.bool,
  };

  if (isEmpty(values) && selectAll.type === "checkbox")
    return <InputCheckbox label={selectAllLabel} disabled />;

  return (
    <InputGroupLayout
      spacing="compact"
      {...props}
      value={checkboxValues}
      inputsComponent={({ disabled, name }) => (
        <Fragment>
          {/** required, data-testid, and ID are applicable for a single input;
           * since this component is a collection of inputs, we discard them */}
          <SelectAllControl />
          {values.map((val, ind) => (
            <Fragment key={`${optionValue(val)}-${ind}`}>
              {isNil(val.options) || val.nestedAsRadio ? (
                generateOption({ val, disabled, name })
              ) : (
                <div className={classNames(selectAllClassName)}>
                  {optionLabel(val)}
                </div>
              )}
              {val.nestedAsRadio ? (
                <NestedRadioGroup val={val} />
              ) : (
                <NestedCheckboxGroup val={val} disabled={disabled} />
              )}
            </Fragment>
          ))}
        </Fragment>
      )}
    />
  );
};

InputCheckboxGroup.propTypes = {
  ...inputCommonPropTypes,
  ...inputGroupCommonPropTypes,
  /** The initial value (uncontrolled) of this input, or the current value (controlled).
   *
   * TODO see if string and shape types work
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
      ),
    ]),
  ]),
  /** Available options to generate checkboxes
   *
   * Note: nestedAsRadio
   */
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        /** a list of options that will be rendered as nested values */
        options: PropTypes.array,
        /** will render the nested options as a radio group instead of as checkboxes */
        nestedAsRadio: PropTypes.bool,
      })
    ),
  ]).isRequired,
  /** `onChange` is invoked with an array of the values selected (just the value) */
  onChange: PropTypes.func,
  /** This HTML `name` is the prefix for `[]` so the values are submitted as a list
   * in HTML form POST submissions. */
  name: PropTypes.string,
  /** When type = "links", adds Select All/Clear options as links to the top of the checkbox group.
   *
   * When type = "checkbox", adds a checkbox with the provided label to the top of the checkbox group.
   *
   * Note: this should NOT be used with a disabled checkbox option
   */
  selectAll: PropTypes.shape({
    type: PropTypes.oneOf(["links", "checkbox"]),
    label: PropTypes.string,
  }),
};
export default InputCheckboxGroup;
