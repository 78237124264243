import { Flex, FlexItem, Text } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";

import { translationWithRoot } from "@components/T";

import styles from "./displayAgencyHumanInfo.module.scss";

const { t } = translationWithRoot("agency_human.summary");
const { t: icwaT } = translationWithRoot("views.common.protected_tribe", {
  escapeJavascriptRoot: true,
});
const { t: inputAddressT } = translationWithRoot(
  "heart.components.inputs.input_address"
);

export const constructIcwaDetails = ({
  protectedTribeMember,
  protectedTribeNames,
  protectedTribeIdentification,
}) => {
  let icwaDetails;
  if (!isNil(protectedTribeMember)) {
    icwaDetails = [
      {
        label: icwaT("t_enrolled_member_fed_recog_tribe"),
        value: protectedTribeMember ? icwaT("answer_yes") : icwaT("answer_no"),
      },
    ];

    if (protectedTribeMember) {
      icwaDetails = [
        ...icwaDetails,
        {
          label: icwaT("federally_recognized_tribe_name"),
          value: protectedTribeNames.join(", "),
        },
        {
          label: icwaT("indian_blood_certificate"),
          value: protectedTribeIdentification,
        },
      ];
    }
  } else {
    icwaDetails = [
      {
        label: I18n.t(
          "javascript.components.agency_human.form.ethnicities_and_icwa.has_person_been_asked"
        ),
        value: icwaT("answer_no"),
      },
    ];
  }

  return icwaDetails;
};

export const constructAddressLabel = ({ type, primary, inactive }) =>
  `${
    !isEmpty(type)
      ? type.map(addrType => inputAddressT(addrType)).join(", ")
      : ""
  }${primary ? ` (${t("primary")})` : ""}${
    inactive ? ` (${t("inactive")})` : ""
  }`;

export const constructAddress = ({
  customFields = {},
  addressLine1,
  addressLine2,
  city,
  primarySubdivision,
  postalCode,
  countryCode,
  countryName,
  inactiveDetails,
}) => {
  /** This is a workaround because we're camelizing props where addresses are
   * passed in as props, but we're not where we're getting them back from graphQL
   */
  const bookingNumber =
    customFields.booking_number || customFields.bookingNumber;
  const facilityName = customFields.facility_name || customFields.facilityName;

  return [
    bookingNumber ? `#${bookingNumber}` : undefined,
    facilityName,
    addressLine1,
    addressLine2,
    `${city ? `${city}, ` : ""}${
      primarySubdivision ? `${primarySubdivision} ` : ""
    }${postalCode}`,
    countryCode !== "US" && countryName,
    inactiveDetails,
  ].filter(Boolean);
};

export const displayAddress = address => {
  const rows = constructAddress(address);
  return (
    <Flex column gap="0">
      {rows.map(row => (
        <FlexItem key={row}>{row}</FlexItem>
      ))}
    </Flex>
  );
};

export const displayPhoneNumbers = ({ childPhoneNumber, phoneNumbers }) => (
  <Flex as="ul" column gap="300" className={styles.list}>
    {childPhoneNumber ||
      phoneNumbers.map(
        ({ category, formattedNumber, primary, active, notes }) => (
          <FlexItem as="li" key={formattedNumber}>
            <Flex column gap="0">
              <Text textStyle="emphasis-100">
                {!isEmpty(category) ? t(category) : ""}
                {primary ? ` (${t("primary")})` : ""}
                {!active ? ` (${t("inactive")})` : ""}
              </Text>
              {formattedNumber}
              <span>{notes}</span>
            </Flex>
          </FlexItem>
        )
      )}
  </Flex>
);

export const displayEmails = ({ emailAddresses }) => (
  <Flex as="ul" column gap="300" className={styles.list}>
    {emailAddresses.map(({ emailAddress, primary, inactive }) => (
      <FlexItem as="li" key={emailAddress}>
        <Flex column gap="0">
          <Text textStyle="emphasis-100">
            {primary ? ` (${t("primary")})` : ""}
            {inactive ? ` (${t("inactive")})` : ""}
          </Text>
          {emailAddress}
        </Flex>
      </FlexItem>
    ))}
  </Flex>
);
