import { useQuery } from "@apollo/client";
import { Layout, LoadingOverlay } from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import { useState } from "react";

import T from "@components/T";
import AgencyServiceReferralsForm from "@components/prevention/agency_service_referrals/form/AgencyServiceReferralsForm";

import AgencyAutocompleteQuery from "@graphql/queries/AgencyAutocomplete.graphql";
import CurrentUser from "@graphql/queries/CurrentUser.graphql";

/**
 * Page with form to create a new agency service referral.
 */
const AgencyServiceReferralsFormPage = () => {
  const [referringAgencyId, setReferringAgencyId] = useState();

  const { data, loading } = useQuery(CurrentUser);

  const displayForm = data?.user?.bintiAdmin
    ? Boolean(referringAgencyId)
    : true;

  const content = (
    <LoadingOverlay active={loading !== undefined ? loading : false}>
      <If condition={data?.user?.bintiAdmin}>
        <SurfaceBase title={"Select Referring Agency (Binti Admin Only)"}>
          <InputAutocompleteGraphQL
            query={AgencyAutocompleteQuery}
            isClearable
            valuesFromResponse={resp =>
              resp.agencyMatches.map(agency => ({
                label: agency.name,
                value: agency.id,
              }))
            }
            onChange={agency => setReferringAgencyId(agency?.value)}
          />
        </SurfaceBase>
      </If>
      <If condition={displayForm}>
        <AgencyServiceReferralsForm referringAgencyId={referringAgencyId} />
      </If>
    </LoadingOverlay>
  );

  return (
    <Layout
      pageTitle={<T t="prevention.agency_service_referrals.form_page.header" />}
      main={{ content }}
    />
  );
};

export default AgencyServiceReferralsFormPage;
