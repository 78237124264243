/* eslint-disable no-underscore-dangle */
import { useQuery, gql } from "@apollo/client";
import { BintiAssistNotice, Flex } from "@heart/components";
import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import I18n from "i18n-js";
import PropTypes from "prop-types";

import AppRequirementReconciliation from "@graphql/queries/ApplicationRequirementReconciliation.graphql";

import { typeEq } from "@lib/graphqlHelpers";

import {
  isNeededForApplication,
  isNeededForCoApplicant,
  isNeededForApplicant,
  isNeededForOtherAdult,
  isNeededForReference,
} from "../../NeededForHelper";
import styles from "./ApplicationRequirementGroups.module.scss";
import ApplicationRequirementTable from "./ApplicationRequirementTable";
import AuxiliaryActionBar from "./AuxiliaryActionBar";
import OtherDocumentsTable from "./OtherDocumentsTable";
import OtherPartnerDocumentsTable from "./OtherPartnerDocumentsTable";

const familyHomeDefinitions = application => [
  {
    title: I18n.t(
      "javascript.components.application_requirements.applicant_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      (isNeededForApplicant(requirement.form.neededFor) ||
        isNeededForCoApplicant(requirement.form.neededFor)),
    id: "ApplicantForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.agency_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      !requirement.form.psychoSocial,
    id: "AgencyForms",
  },
  {
    title: I18n.st(application.agency.stateAbbr, "psycho_social"),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      requirement.form.psychoSocial,
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.reference_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForReference(requirement.form.neededFor),
  },
  {
    title: I18n.t("javascript.components.application_requirements.oa_forms"),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForOtherAdult(requirement.form.neededFor),
  },
  {
    title: "Supporting Documents",
    filter: ({ requirement }) => typeEq("UploadTypeRequirement", requirement),
    id: "SupportingDocuments",
  },
];

const organizationDefinitions = [
  {
    title: I18n.t(
      "javascript.components.application_requirements.provider_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplicant(requirement.form.neededFor),
    id: "ApplicantForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.licensor_forms"
    ),
    filter: ({ requirement }) =>
      typeEq("ApplicationFormRequirement", requirement) &&
      isNeededForApplication(requirement.form.neededFor) &&
      !requirement.form.psychoSocial,
    id: "AgencyForms",
  },
  {
    title: I18n.t(
      "javascript.components.application_requirements.supporting_documents"
    ),
    filter: ({ requirement }) => typeEq("UploadTypeRequirement", requirement),
    id: "SupportingDocuments",
  },
];

const licenseQuery = gql`
  query licenseByApplicationId($applicationId: ID!) {
    licenseByApplicationId(applicationId: $applicationId) {
      id
      licenseType
    }
  }
`;

const groupDefinitions = (application, licenseType) => {
  if (
    ["congregate_care", "child_placing_agency"].includes(
      licenseType || application.license.licenseType
    )
  ) {
    return organizationDefinitions;
  }
  // familyHome or null
  return familyHomeDefinitions(application);
};

/**
 * This component is the main page content for the caregiver documents v2
 * (requirements) page. It displays tables of requirements for the application,
 * table(s) of unmatched documents, and an auxiliary action bar.
 */
const ApplicationRequirementGroups = ({
  applicationId,
  currentUserBintiAdmin,
  mayCreateConditionalRequirements,
  showOtherDocumentsTable,
  showOtherPartnerDocumentsTable,
}) => {
  // some user roles, such as eligibility, do not have permission to see the
  // application. this causes problems when trying to access
  // application.license.licenseType.
  const { data: licenseData } = useQuery(licenseQuery, {
    variables: { applicationId: applicationId },
  });

  const { loading, error, data } = useQuery(AppRequirementReconciliation, {
    variables: { applicationId },
  });
  if (loading) {
    // TODO actually get some design for this spinner
    return (
      <Flex justify="center">
        <div style={{ height: "200px", width: "200px", margin: "30px" }}>
          <LogoSpinner />
        </div>
      </Flex>
    );
  }
  if (error) return <p>Error :/</p>;

  const { applicationRequirementReconciliation, application } = data;
  const { licenseType } = licenseData.licenseByApplicationId;
  const { unmatchedRecords } = applicationRequirementReconciliation;
  const filteredFulfillments = filter =>
    applicationRequirementReconciliation.requirementFulfillments.filter(filter);

  const reviewRequired =
    applicationRequirementReconciliation.requirementFulfillments.some(
      ({ records }) =>
        records.some(
          ({ reviewRequired: formReviewRequired }) => formReviewRequired
        )
    );

  return (
    <Flex column>
      <Flex
        row
        justify={reviewRequired ? "space-between" : "end"}
        className={styles.auxillaryArea}
      >
        <If condition={reviewRequired}>
          <BintiAssistNotice>
            {I18n.t(
              "views.application_stages.admin_normal.transcription_notice"
            )}
          </BintiAssistNotice>
        </If>
        <AuxiliaryActionBar
          application={application}
          currentUserBintiAdmin={currentUserBintiAdmin}
          mayCreateConditionalRequirements={mayCreateConditionalRequirements}
        />
      </Flex>
      <If condition={licenseType || application.license.licenseType}>
        {groupDefinitions(application, licenseType).map(
          ({ filter, id, title }) => (
            <ApplicationRequirementTable
              key={title}
              requirementFulfillments={filteredFulfillments(filter)}
              {...{ application, id, title }}
            />
          )
        )}
      </If>
      <If condition={showOtherDocumentsTable}>
        <OtherDocumentsTable
          {...{
            application,
            unmatchedRecords,
            showingOtherPartnerDocumentsTable: showOtherPartnerDocumentsTable,
          }}
        />
      </If>
      <If condition={showOtherPartnerDocumentsTable}>
        <OtherPartnerDocumentsTable {...{ application, unmatchedRecords }} />
      </If>
    </Flex>
  );
};

ApplicationRequirementGroups.propTypes = {
  applicationId: PropTypes.number.isRequired,
  currentUserBintiAdmin: PropTypes.bool,
  mayCreateConditionalRequirements: PropTypes.bool,
  showOtherDocumentsTable: PropTypes.bool.isRequired,
  showOtherPartnerDocumentsTable: PropTypes.bool.isRequired,
};

export default ApplicationRequirementGroups;
